import React from 'react'
import { Box, Grid, Typography, Link, useMediaQuery, useTheme } from '@mui/material'
import sideBanner from '../../src/assets/sideBanner.svg'
import mobileFoot from '../../src/assets/mobileFoot.svg'
import markaazLogo from '../../src/assets/markaazLogoWhite.svg'
import markaaz from '../../src/assets/markaazLogoBlue.svg'

const BlockerScreen = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const styles = {
    header: {
      color: '#0A78CD',
      fontSize: isMobile ? '21px' : '24px',
      fontWeight: '500',
      fontFamily: 'Poppins',
      lineHeight: isMobile ? '31.5px' : '44px',
      maxWidth: isMobile ? '327px' : '649px',
    },
    content: {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: isMobile ? '14px' : '16px',
      lineHeight: isMobile ? '24px' : '28px',
      color: '#25245F',
      maxWidth: isMobile ? '327px' : '649px',
      marginTop: '28px',
    },
    bannerText: {
      fontFamily: 'Poppins',
      fontWeight: '500',
      fontSize: '35.29px',
      lineHeight: '50.6px',
      color: '#00E2BC',
      maxWidth: '332.78px',
    },
    link: {
      fontFamily: 'Poppins',
      fontWeight: '500',
      fontSize: isMobile ? '14px' : '16px',
      lineHeight: '28px',
      color: '#0A78CD',
    },
  }

  return (
    <Grid container style={{ height: '100vh', alignContent: isMobile ? 'flex-start' : '' }}>
      {!isMobile ? (
        <Grid item xs={12} xl={3} md={4}>
          <Box
            style={{
              background: `url(${sideBanner}) no-repeat center center`,
              backgroundSize: 'cover',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '450px',
              color: '#fff',
            }}
          >
            <Box display="flex">
              <Link href="https://markaaz.com/" underline="none">
                <img
                  src={markaazLogo}
                  alt="Markaaz Logo"
                  style={{
                    height: '42px',
                    width: '165px',
                    margin: '64.56px 0px 0px 70.52px',
                  }}
                />
              </Link>
            </Box>
            <Box display="flex" sx={{ padding: '36px 0px 0px 66px' }}>
              <Typography sx={styles.bannerText}>The Global Business Identity Platform</Typography>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12} md={8}>
          <Box>
            <Link href="https://markaaz.com/" underline="none">
              <img
                src={markaaz}
                alt="Markaaz Logo"
                style={{
                  height: '29.2px',
                  width: '144px',
                  margin: '60.4px 0px 0px 20px',
                }}
              />
            </Link>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} xl={9} md={8}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={!isMobile ? 'center' : 'flex-start'}
          alignItems="center"
          height="100%"
        >
          <Box>
            <Typography sx={styles.header} mt={3}>
              Dear Markaaz Business Dashboard Users,
            </Typography>
            <Box sx={styles.content}>
              <Typography sx={styles.content}>Thank you for having been a great customer and user of our platform.</Typography>
              <Typography mt={3} sx={styles.content}>
                We wanted to make you aware that we are transitioning all users to our business verification platform,
                where you can verify the identity of your suppliers and partners, and check if they are in good
                standing.
              </Typography>
              <Typography mt={3} sx={styles.content}>
                If having that peace of mind is of interest to you, we'd like to offer a 90-day trial.
              </Typography>
              <Typography mt={3} mb={3} sx={styles.content}>
                Any questions you have, let us know.
              </Typography>

              <Link
                href="https://share.hsforms.com/1n5-LEIiqTUyScicS-czMBAcdn4z"
                rel="noreferrer"
                target="_blank"
                underline="hover"
              >
                <Typography component="span" sx={styles.link} >
                  support@markaaz.com
                </Typography>
              </Link>
              <Typography mt={4} mb={4} sx={styles.content}>
                Kindest regards,
                <br /> Markaaz Team
              </Typography>
            </Box>
          </Box>
          {isMobile && (
           
              <img
                src={mobileFoot}
                alt="Markaaz footer"
                style={{
                  width: '100%',
                  height: 'auto',
                  backgroundSize: 'cover',

                }}
              />
           
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default BlockerScreen
