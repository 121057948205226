import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
ReactGA.initialize(process.env.REACT_APP_GA_TRCKING_ID)

const GoogleAnalyticsWrapper = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [location])

  return children
}

export default GoogleAnalyticsWrapper
