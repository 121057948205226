import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import GoogleAnalyticsWrapper from 'layouts/GoogleAnalyticsWrapper'
import theme from './themes/theme'

import '@progress/kendo-theme-default/dist/all.css'
import './App.css'
import { StyledEngineProvider } from '@mui/material'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import { datadogRum } from '@datadog/browser-rum'
import BlockerScreen from 'pages/BlockerScreen'

function App() {
  const env = process.env['REACT_APP_DATA_DOG_ENV']

  datadogRum.init({
    applicationId: '53491283-7177-4ad6-9a0e-cdbb62ffe01a',
    clientToken: 'pubd84bc9457d53557ce56ef30947143c2f',
    site: 'datadoghq.com',
    service: 'dashboard',
    env: env,
    version: '1.14.15',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()

  if (process.env['REACT_APP_SITE_PLACEHOLDER'] === '1') {
    return (
      <div className="col-md-12 d-flex justify-content-center">
        <img
          className="img-fluid"
          src={process.env['REACT_APP_BRC_IMAGE_URL'] + '/site-placeholder/placeholder.png'}
          usemap="#image-map"
          alt="placeholder"
        />

        <map name="image-map">
          <area target="" alt="" title="" href="https://markaaz.com/dashboard/" coords="1079,1079,0,984" shape="rect" />
        </map>
      </div>
    )
  } else {
    return (
      <I18nextProvider i18n={i18n}>
        <Router>
          <GoogleAnalyticsWrapper>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route path="/" element={<BlockerScreen />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </ThemeProvider>
            </StyledEngineProvider>
          </GoogleAnalyticsWrapper>
        </Router>
      </I18nextProvider>
    )
  }
}

export default App

