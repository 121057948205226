import { createTheme } from '@mui/material/styles'

export const primaryTextColor = '#25245F'
export const secondaryTextColor = '#0A78CD'
export const whiteTextColor = '#FFFFFF'
export const purple = '#BB07DC'
export const aqua = '#00E2BC'
export const primaryButtonBG = 'linear-gradient(270deg, #00CBA9 0%, #0099FF 100%)'
export const primaryButtonHoverBG = 'linear-gradient(270deg, #00B596 0%, #0A78CD 100%)'
export const secondaryButtonBG =
  'linear-gradient(white, white) padding-box, linear-gradient(to right, #0099ff 0%, #00CBA9 100%) border-box'
export const secondaryButtonHoverBG =
  'linear-gradient(to right, rgb(236, 248, 250), rgb(236, 248, 250)) padding-box, linear-gradient(to right, #0099ff 0%, #00CBA9 100%) border-box '

export const secondaryNegativeButtonBG =
  'linear-gradient(white, white) padding-box, linear-gradient(to right, #D14343 0%, #D14343 100%) border-box'
export const secondaryNegativeButtonHoverBG =
  'linear-gradient(to right, #FDF6F6, #FDF6F6) padding-box, linear-gradient(to right, #D14343 0%, #D14343 100%) border-box '
// NOTE: Main Button types
export const PRIMARY_BUTTON = 'primary_button'
export const SECONDARY_BUTTON = 'secondary_button'
export const DISABLED_BUTTON = 'disabled_button'

/// NOTE: Deprecated
export const EMPHASIZED_BUTTON = 'emphasized_button'
export const primaryFont = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
'Droid Sans', 'Helvetica Neue', sans-serif`

export const tealPieChartColor = 'rgb(0, 226, 188)'
export const greyPieChartColor = '#d1d7e0'
export const lightGrey = 'rgb(232, 232, 232)'
export const emphasizedButtonBG = 'linear-gradient(270deg, #700484 0%, #BB07DC 100%)'
// const color2 = '#FF5A5F' // kinda red

const palette = {
  primary: {
    main: primaryTextColor,
  },
  secondary: {
    main: secondaryTextColor,
  },
  negative: {
    main: '#D14343',
  },
  text: {
    primary: primaryTextColor,
    secondary: secondaryTextColor,
    purple: purple,
    aqua: aqua,
    white: whiteTextColor,
  },
  background: {
    primary: primaryButtonBG,
    primaryHover: primaryButtonHoverBG,
    secondary: secondaryButtonBG,
    secondaryHover: secondaryButtonHoverBG,
    negativePrimary: '#D14343',
    negativePrimaryHover: '#A73636',
    negativeSecondary: secondaryNegativeButtonBG,
    negativeSecondaryHover: secondaryNegativeButtonHoverBG,
    sidebar: '#25245F',
    darkBlue: '#25245F',
    white: '#FFFFFF',
  },
  color: {
    postive: '#52BD94',
    postive130: '#398468',
    postive5: '#F6FCFA',
    negative: '#D14343',
    negative120: '#A73636',
    negative5: '#FDF6F6',
    darkBlue: '#25245F',
    darkBlue90: '#3B3A6F',
    darkBlue70: '#66668F',
    darkBlue50: '#9292AF',
    darkBlue30: '#BDBDCE',
    darkBlue20: '#D3D3DF',
    darkBlue10: '#E9E9EF',
    darkBlue5: '#F4F4F7',
    blue: '#0a78cd',
    blueLight: '#0099ff',
    blueLight2: '#0A78CD',
    blue20: '#0a78cd',
    blue5: '#f2f8fc',
    aqua: '#00e2bc',
    aqua2: '#00B596',
    aqua110: '#00CBA9',
    aqua120: '#00B596',
    purple: '#bb07dc',
    purple140: '#9606b0',
    purple120: '#700484',
    white: "#ffffff"
  },
}

const typography = {
  fontFamily: 'Poppins',
  h1: {
    fontSize: '96px',
    lineHeight: '110px',
    fontWeight: '600',
  },
  h2: {
    fontSize: '60px',
    lineHeight: '80px',
    fontWeight: '600',
  },
  h3: {
    fontSize: '48px',
    lineHeight: '70px',
    fontWeight: '600',
  },
  h4: {
    fontSize: '34px',
    lineHeight: '50px',
    fontWeight: '600',
  },
  h5: {
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: '600',
  },
  h6: {
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '600',
  },
  quote1: {
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: '500',
  },
  quote2: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '500',
  },
  body1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
  },
  body1M: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  body2M: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
  },
  body1B: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
  },
  body2B: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 700,
  },
  button1: {
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: 700,
  },
  button2: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
  },
  button3: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 700,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  overline1: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  overline2: {
    fontSize: '10px',
    lineHeight: '14px',
    fontWeight: '600',
    letterSpacing: '1px',
  },
  overline3: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1px',
  },
}

const zIndex = {
  overlay: 1,
  modal: 2,
}

const sizes = {
  header: {
    height: '100px',
  },
  sideBar: {
    reducedWidth: '80px',
    width: '200px',
  },
  input: {
    height: {
      small: '30px',
      medium: '40px',
      large: '50px',
    },
  },
}

const components = {
  MuiAccordion: {
    styleOverrides: {
      root: () => ({
        marginBottom: '20px',
        boxShadow: 'none',
        position: 'inherit',
        borderRadius: '10px !important',
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: () => ({
        color: '#0A78CD',
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: () => ({
        justifyContent: 'unset',
        minHeight: '68px',
      }),
      content: () => ({
        alignItems: 'center',
        flexGrow: '0',
      }),
      expandIconWrapper: () => ({
        marginLeft: '10px',
        '.MuiSvgIcon-root': {
          fontSize: '25px',
          color: palette.color.blue,
        },
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: () => ({
        '&:hover': {
          textDecorationColor: secondaryTextColor,
        },
      }),
    },
  },
  MuiAutocomplete: {
    defaultProps: {},
    styleOverrides: {
      root: () => ({
        '.MuiAutocomplete-popupIndicator': {
          borderRadius: '5px',
          backgroundColor: '#F4F4F7',
        },

        '.MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
          fontSize: '24px',
          color: '#9292AF',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #0A78CD !important',
        },
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: () => ({
        '&.Mui-error': {
          color: '#D14343',
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: () => ({
        '&.Mui-checked': {
          color: '#0A78CD !important',
        },
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: () => ({
        '&.MuiAlert-standardError': {
          border: '1px solid #D14343',
          backgroundColor: '#FDF6F6',
        },
        '&.MuiAlert-standardSuccess': {
          border: '1px solid #398468',
          backgroundColor: '#F6FCFA',
        },
        '&.MuiAlert-root': {
          borderRadius: '8px',
          fontSize: '13px',
          paddingTop: '3px',
          paddingBottom: '3px',
          alignItems: 'center',
        },
        '.MuiAlert-icon': {
          fontSize: '16px',
        },
        '&.MuiAlert-standardError .MuiAlert-message': {
          color: '#D14343',
        },
        '&.MuiAlert-standardError .MuiAlert-icon': {
          color: '#D14343',
        },
        '&.MuiAlert-standardError .MuiAlert-action': {
          color: '#D14343',
        },
        '&.MuiAlert-standardSuccess .MuiAlert-message': {
          color: '#398468',
        },
        '&.MuiAlert-standardSuccess .MuiAlert-icon': {
          color: '#398468',
        },
        '&.MuiAlert-standardSuccess .MuiAlert-action': {
          color: '#398468',
        },
        '.MuiAlert-action': {
          paddingTop: '0px',
        },
      }),
    },
  },
}

const theme = createTheme({ palette, typography, sizes, zIndex, components })

export default theme
